import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Table, Divider, Avatar, Select } from "antd";
import { AUTHENTICATED_URL } from "../../baseURL";
import { useUser } from "context/UserContext";
import ModalUpgradePlan from "components/Modals/ModalUpgradePlan/ModalUpgradePlan";
import Loading from "components/Loading/Loading";
import styles from "./Dashboard.module.css";

function Dashboard() {
  let token = localStorage.getItem("token");
  const { user, plan } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  const [totalLeads, setTotalLeads] = useState([]);
  const [leads, setLeads] = useState([]);
  const [meetings, setMeetings] = useState(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('month');

  useEffect(() => {
    setPageLoading(true);

    const fetchData = async () => {
      await getLeads();
      await getMeetings();
      setPageLoading(false);
    };
  
    fetchData();
  }, [plan]);

  const isCurrentMonth = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const leadDate = new Date(date);
    return leadDate.getFullYear() === today.getFullYear() && leadDate.getMonth() === today.getMonth();
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  
    let filtered;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    switch (value) {
      case 'today':
        filtered = totalLeads.filter(lead => {
          const leadDate = new Date(lead.createdAt);
          leadDate.setHours(0, 0, 0, 0);
          return leadDate.getTime() === today.getTime();
        });
        break;
      case 'month':
        filtered = totalLeads.filter(lead => isCurrentMonth(lead.createdAt));
        break;
      case 'total':
        filtered = totalLeads;
        break;
      default:
        filtered = totalLeads;
    }
  
    setLeads(filtered);
  };

  const getLeads = async () => {
    try {
      const response = await axios.get(`${AUTHENTICATED_URL}/leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const leads = response.data.leads;
      setTotalLeads(plan === 'free' ? [] : leads);
  
      const thisMonthLeads = leads.filter(lead => isCurrentMonth(lead.created_at));
      setLeads(thisMonthLeads);
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetings = async () => {
    try {
      const response = await axios.get(`${AUTHENTICATED_URL}/meetings`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const meetings = response.data.meetings;
      let meetingsFiltered = meetings.filter(
        (meeting) => new Date(meeting.date) >= new Date()
      );
      setMeetings(meetingsFiltered);
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: "Scoring",
      dataIndex: "meeting",
      key: "rating",
      render: (_, meeting) => <div>{meeting.lead.lead_scoring}</div>,
    },
    {
      title: "Company",
      dataIndex: "lead_company",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (_, meeting) => <div>{meeting.lead.position}</div>,
    },
    {
      title: "Work email",
      dataIndex: "email",
      key: "email",
      render: (_, meeting) => <div>{meeting.lead.email}</div>,
    },
    {
      title: "Meeting Date",
      dataIndex: "date",
      key: "date",
      render: (_, meeting) => (
        <div>{moment(meeting.date).format("DD-MM-YYYY HH:mm")}</div>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assigned",
      key: "assigned",
      render: (_, meeting) => (
        <div>
          <Avatar
            size="large"
            src={meeting.user.image}
            style={{ marginRight: 10 }}
          />
          {meeting.user.fullName}
        </div>
      ),
    },
  ];

  const onOpenModal = () => {
    if (plan === "free") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  const optionsSelect = [
    { label: 'Today', value: 'today' },
    { label: 'This Month', value: 'month' },
    { label: 'Total', value: 'total' },
  ];
  
  if (pageLoading) {
    return renderLoading();
  }

  return (
    user && (
      <>
        <ModalUpgradePlan open={open} onClose={onCloseModal}>
          You have a free Weyzo account which not allows you to view analytics.
          Want to view it?
        </ModalUpgradePlan>
        <div onClick={onOpenModal} className={plan === 'free' ? styles['blur'] : ''}>
          <div className={`${styles["container-filters"]}`}>
            <div className="regular-text">Tuesday 23th, June 2022</div>
            <Select defaultValue={filter} onChange={handleFilterChange} options={optionsSelect} style={{ width: 150 }} />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 15,
              marginTop: 40,
              marginBottom: 40,
            }}
          >
            <div
              className={`container-lg ${styles["card-dashboard"]}`}
              style={{ flex: "1 31%" }}
            >
              <div className="title-purple">{leads.length}</div>
              <div className="default-text">
                {user.user_type === "sales"
                  ? "Assigned Leads"
                  : "Total leads"}
              </div>
            </div>
            <div
              className={`container-lg ${styles["card-dashboard"]}`}
              style={{ flex: "1 31%" }}
            >
              <div className="title-purple">
                {leads.filter(
                  (option) => option.status === "qualified"
                ).length +
                leads.filter(
                    (option) => option.status === "disqualified"
                  ).length}
              </div>
              <div className="default-text">Completed Forms</div>
            </div>
            <div
              className={`container-lg ${styles["card-dashboard"]}`}
              style={{ flex: "1 31%" }}
            >
              <div className="title-purple">
                {
                  leads.filter(
                    (option) => option.status === "uncompleted"
                  ).length
                }
              </div>
              <div className="default-text">Uncompleted Forms</div>
            </div>
            <div
              className={`container-lg ${styles["card-dashboard"]}`}
              style={{ flex: "1 31%" }}
            >
              <div className="title-purple">
                {
                  leads.filter(
                    (option) => option.status === "qualified"
                  ).length
                }
              </div>
              <div className="default-text">Qualified Leads</div>
            </div>
            <div
              className={`container-lg ${styles["card-dashboard"]}`}
              style={{ flex: "1 31%" }}
            >
              <div className="title-purple">
                {
                  leads.filter(
                    (option) => option.status === "disqualified"
                  ).length
                }
              </div>
              <div className="default-text">Disqualified Leads </div>
            </div>
            <div
              className={`container-lg ${styles["card-dashboard"]}`}
              style={{ flex: "1 31%" }}
            >
              <div className="title-purple">
                {
                  leads.filter(
                    (option) =>
                      option.meeting_date !== "0000-00-00 00:00:00" &&
                      option.meeting_date !== "" &&
                      option.meeting_date !== null
                  ).length
                }
              </div>
              <div className="default-text">Meetings Booked</div>
            </div>
          </div>
          <div className={`${styles["container-upcoming-meetings"]}`}>
            <div className="regular-text" style={{ fontSize: 16 }}>
              New Upcoming Meetings
            </div>
            <Divider style={{ margin: "15px 0" }} />
            <Table
              columns={columns}
              dataSource={meetings}
              onChange={onChange}
              pagination={false}
            />
          </div>
        </div>
      </>
    )
  );
}

export default Dashboard;
