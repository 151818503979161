import { Routes, Route, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivateRoute from "helpers/PrivateRoute";
import FullWidthLayout from "views/Layouts/FullWidthLayout/FullWidthLayout";
import AppLayout from "views/Layouts/AppLayout/AppLayout";
import Dashboard from "views/Dashboard/Dashboard";
import Forms from "views/Forms/Forms";
import Leads from "views/Leads/Leads";
import Login from "views/login/login";
import CheckEmail from "views/checkEmail/checkEmail";
import Register from "views/register/register";
import Welcome from "views/Welcome/Welcome";
import Lead from "views/Lead/Lead";
import Meetings from "views/Meetings/Meetings";
import Profile from "views/Profile/Profile";
import CompanyProfile from "views/CompanyProfile/CompanyProfile";
import TeamUser from "views/TeamUser/TeamUser";
import Availability from "views/Availability/Availability";
import NewUser from "views/NewEditUserCompany/newuser";

import PasswordReset from "views/password-reset/password-reset";
import PasswordChanged from "views/passwordChanged/passwordChanged";

import NewForm from "views/FormBuilder/NewForm";
import EditForm from "views/FormBuilder/EditForm";
import UpgradePlan from "views/UpgradePlan/UpgradePlan";

function Router() {

  return (
    <Routes location={useLocation()}>
      <Route 
        path="/"
        element={
          <PrivateRoute>
            <AppLayout>
              <Dashboard />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route 
        path="/forms"
        element={
          <PrivateRoute>
            <AppLayout>
              <Forms />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/leads"
        element={
          <PrivateRoute>
            <AppLayout>
              <Leads />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/leads/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <Lead />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/meetings"
        element={
          <PrivateRoute>
            <AppLayout>
              <Meetings />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/profile"
        element={
          <PrivateRoute>
            <AppLayout>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <Profile />
              </GoogleOAuthProvider>
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/company_profile"
        element={
          <PrivateRoute>
            <AppLayout>
              <CompanyProfile />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/team-user/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <TeamUser />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/availability"
        element={
          <PrivateRoute>
            <AppLayout>
              <Availability />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/user_company"
        element={
          <PrivateRoute>
            <AppLayout>
              <NewUser />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/user_company/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <NewUser />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/google-response"
        element={
          <PrivateRoute>
            <AppLayout>
              <Profile />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route
        path="/upgrade-plan"
        element={
          <PrivateRoute>
            <FullWidthLayout whiteBackground>
              <UpgradePlan />
            </FullWidthLayout>
          </PrivateRoute>
        }
      />
        <Route
          path="/edit-form/:id"
          element={
            <PrivateRoute>
              <FullWidthLayout>                
                <EditForm />
              </FullWidthLayout>
            </PrivateRoute>
          }
        />
        <Route 
          path="/form-builder"
          element={
            <PrivateRoute>
              <FullWidthLayout>                
                <NewForm />
              </FullWidthLayout>
            </PrivateRoute>
          } 
        />
      <Route
        path="/login"
        element={
          <FullWidthLayout>
            <Login />
          </FullWidthLayout>
        }
      />
      <Route 
        path="/register"
        element={
          <FullWidthLayout>
            <Register />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/checkEmail"
        element={
          <FullWidthLayout>
            <CheckEmail />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/welcome"
        element={
          <FullWidthLayout>
            <Welcome />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/password-reset/:token"
        element={
          <FullWidthLayout>
            <PasswordReset />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/passwordChanged"
        element={
          <FullWidthLayout>
            <PasswordChanged />
          </FullWidthLayout>
        } 
      />
    </Routes>
  );
}

export default Router;
