import React, { useEffect, useState } from "react";
import { ContentState, convertToRaw, convertFromHTML, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./textRich.module.css";
import styles from "./textRich.module.css";

const TextRich = ({ onChange, value, placeholder }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : "";
      const blocksFromHtml = convertFromHTML(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value, updated]);

  const onEditorStateChange = (editorState) => {
    setUpdated(true);
    setEditorState(editorState);

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const html = rawContentState.blocks.map(block => (!block.text.trim() && "\n") || block.text).join("\n");

    return onChange(html);
  };

  return (
    <>
      <Editor
        editorClassName={styles.editor}
        placeholder={placeholder}
        spellCheck
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ["inline", "link", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ["ordered", "unordered"],
          },
          link: {
            options: ["link"],
          },
        }}
      />
    </>
  );
};

export default TextRich;
