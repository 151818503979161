import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import "./buttons.module.css";
import styles from "./buttons.module.css";

import upgradeIcon from "../../assets/img/buttonsLogos/lightning-fill.svg";

import { useUser } from "../../context/UserContext";

export const UpgradeButton = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/upgrade-plan");
  };
  const renderButton = () => {
    return (
      <>
        <div className={styles["upgrade-container"]}>
          <p className={styles["upgrade-text"]}>Upgrade</p>
          <img
            src={upgradeIcon}
            alt="upgrade icon"
            className={styles["upgrade-icon"]}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <button onClick={onClick} className={styles["upgrade-button"]}>
        {renderButton()}
      </button>
    </>
  );
};

export const UpgradePlanButton = ({ planLabel, onClick, loading }) => {
  const { plan } = useUser();
  switch (planLabel) {
    case "free":
      return (
        <>
          <button
            disabled={true}
            onClick={onClick}
            className={`${styles["plan-free"]} ${styles["plan-button"]}`}
          >
            {plan === 'professional' ? "Already included in Professional Plan" : "Current"}
          </button>
        </>
      );
    case "professional":
      return (
        <>
          <button
            disabled={plan === 'professional'}
            onClick={onClick}
            className={`${styles["plan-pro"]} ${styles["plan-button"]}`}
          >
            {loading ? (
              <>
                <LoadingOutlined />
              </>
            ) : plan === 'professional' ? (
              "Current"
            ) : (
              "Upgrade"
            )}
          </button>
        </>
      );
    case "enterprise":
      return (
        <>
          <button
            disabled={plan === 'enterprise'}
            onClick={onClick}
            className={`${styles["plan-enterprise"]} ${styles["plan-button"]}`}
          >
            {plan === 'enterprise' ? "Current" : "Talk to sales"}
          </button>
        </>
      );
    default:
      return <></>;
  }
};

export const UpgradeModalButton = ({ children }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/upgrade-plan");
  };
  return (
    <>
      <div>
        <button onClick={onClick} className={styles["upgrade-modal"]}>
          <p>{children}</p>
        </button>
      </div>
    </>
  );
};

export const UpgradeLeadsButton = ({ children }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/upgrade-plan");
  };
  return (
    <>
      <div>
        <button
          onClick={onClick}
          className={`${styles["upgrade-leads"]}`}
        >
          <p> {children} </p>
        </button>
      </div>
    </>
  );
};
