import { Avatar, Divider } from "antd";
import WInput from "components/w-input/w-input";
import { useEffect, useState } from "react";
import { useUser } from "context/UserContext";
import { ReactComponent as Edit } from "assets/img/edit.svg";
import { ReactComponent as Trash } from "assets/img/trash.svg";
import { useParams, useNavigate } from "react-router-dom";
import { message, Button } from "antd";
import moment from "moment";

import { BASE_URL } from "../../baseURL";

const TeamUser = () => {
  const { user: userFromContext } = useUser();
  const [user, setUser] = useState(userFromContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");
  const [editUser, setEditUser] = useState(true);

  let navigate = useNavigate();
  const { id } = useParams();
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (userFromContext.user_type === "sales") {
      navigate("/");
    }
    getUser();
  }, []);

  const getUser = async () => {
    return await fetch(`${BASE_URL}/users/show/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((userData) => {
        setUser(userData);
        setName(userData.full_name);
        setEmail(userData.email);
        setPosition(userData.job_title);
        setRole(userData.user_type);
        return userData;
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error);
      });
  };

  const updateUser = async () => {
    let body = JSON.stringify({
      id: user.id,
      full_name: name,
      email: email,
      job_title: position,
      user_type: role,
    });
    return await fetch(`${BASE_URL}/update_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: body,
    })
      .then((response) => response.json())
      .then((json) => {
        setUser(json);
        setName(json.fullName);
        setEmail(json.emailAddress);
        setPosition(json.job_title);
        setRole(json.user_type);
        setEditUser(true);
        return json;
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error);
      });
  };

  const deleteUser = async () => {
    if (window.confirm("¿Desea eliminar el usuario?")) {
      return await fetch(`${BASE_URL}/delete_user/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          navigate("/company_profile");
          return json;
        })
        .catch((error) => {
          message.error("Lead not found");
          console.error(error);
        });
    } else {
    }
  };

  return (
    user !== null && (
      <>
        <div className="container-lg">
          <div
            className="title-purple"
            style={{
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            User Profile
            <div style={{ display: "flex", alignItems: "center" }}>
              {editUser ? (
                <Edit
                  style={{ marginRight: 20 }}
                  onClick={() => setEditUser(!editUser)}
                />
              ) : (
                <Button
                  style={{ marginRight: 10 }}
                  size="large"
                  className="primary-btn font-regular"
                  onClick={() => updateUser()}
                >
                  Save
                </Button>
              )}
              <Trash onClick={() => deleteUser()} />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 30,
                flex: 1,
              }}
            >
              <Avatar
                style={{ marginRight: 20 }}
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                src={`${user.image}`}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="title-purple">{user.fullName}</span>
                <span className="default-text">{user.emailAddress}</span>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <WInput
                label="Full Name"
                placeholder="John"
                value={name}
                onChangeValue={(value) => setName(value)}
                style={{ marginBottom: 20 }}
                disabled={editUser}
              />
              <WInput
                label="Work Email"
                placeholder="John@weyzo.io"
                value={email}
                onChangeValue={(value) => setEmail(value)}
                style={{ marginBottom: 20 }}
                disabled={editUser}
              />
              <WInput
                label="Position"
                placeholder="Project Manager"
                value={position}
                onChangeValue={(value) => setPosition(value)}
                style={{ marginBottom: 20 }}
                disabled={editUser}
              />
              <WInput
                label="User Role"
                placeholder=""
                value={role}
                onChangeValue={(value) => setRole(value)}
                style={{ marginBottom: 20 }}
                disabled={editUser}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "50%" }}>
                  <p
                    className="font-medium"
                    style={{
                      margin: 0,
                      color: "var(--gray-placeholder-light)",
                    }}
                  >
                    Calendar Connection
                  </p>
                  <p
                    className="font-medium"
                    style={{ margin: 0, color: "var(--gray-700)" }}
                  >
                    {user.calendar_id !== undefined &&
                    user.calendar_id !== null &&
                    user.calendar_id !== ""
                      ? "Connected"
                      : "Disconnected"}
                  </p>
                </div>
                <Divider
                  type="vertical"
                  style={{ height: "3em", marginRight: "5%" }}
                />
                <div style={{ width: "50%" }}>
                  <p
                    className="font-medium"
                    style={{
                      margin: 0,
                      color: "var(--gray-placeholder-light)",
                    }}
                  >
                    Last Sign In
                  </p>
                  <p
                    className="font-medium"
                    style={{ margin: 0, color: "var(--gray-700)" }}
                  >
                    {moment(user.lastSeenAt).format("DD.MM.YY - HH:mm")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default TeamUser;
