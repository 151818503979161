import React from "react";
import SwitchInputGeneric from "components/SwitchInput/SwitchInputGeneric";
import styles from '../profile.module.css';

const NotificationSettings = ({onChangeSwitch, notificationSetting}) => {
  return (
    <>
      <div className="container-lg" style={{ marginBottom: 30 }}>
      <div
          className="title-purple"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #ECECEC",
            paddingBottom: 5,
            marginBottom: 15,
          }}
        >
          Email Notifications
        </div>
        <div
          className="font-medium"
          style={{ color: "var(--gray-placeholder-dark)", marginBottom: 30 }}
        >
          Notify me when...
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: 'flex-start'
          }}
        >
          <div>
            <div className={styles['switch-group']}>
              <SwitchInputGeneric title='notification' name='new_lead' onChange={onChangeSwitch} value={notificationSetting?.new_lead} />
              <p>New inbound lead</p>
            </div>
            <div className={styles['switch-group']}>
              <SwitchInputGeneric title='notification' name='new_meeting' onChange={onChangeSwitch} value={notificationSetting?.new_meeting} />
              <p>New meeting scheduled</p>
            </div>
          </div>
          <div>
            <div className={styles['switch-group']}>
              <SwitchInputGeneric title='notification' name='new_lead_assigned' onChange={onChangeSwitch} value={notificationSetting?.new_lead_assigned} />
              <p>New lead assigned</p>
            </div>
            <div className={styles['switch-group']}>
              <SwitchInputGeneric title='notification' name='new_meeting_assigned' onChange={onChangeSwitch} value={notificationSetting?.new_meeting_assigned} />
              <p>New meeting scheduled and assigned</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationSettings;
