import React from "react";
import { Select } from "antd";

const { Option } = Select;

function WSelect({
  label,
  value,
  options,
  style,
  placeholder,
  onChange,
  disabled,
}) {
  return (
    <div style={style}>
      <div
        className="font-medium"
        style={{ marginBottom: 5, color: "var(--gray-700)" }}
      >
        {label}
      </div>
      <Select
        size="large"
        showSearch
        style={{
          width: "100%",
        }}
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA.children?.toLowerCase() ?? "").localeCompare(optionB.children?.toLowerCase() ?? "")
        }
        value={value}
        onChange={(value) => onChange(value)}
        disabled={disabled}
        className="wselect"
      >
        {options.map((opt) => {
          return <Option value={opt.value}>{opt.name}</Option>;
        })}
      </Select>
    </div>
  );
}

export default WSelect;
