export const generateFixedQuestions = (fixedQuestions = []) => {
  
  const formFixedQuestions = fixedQuestions
    .sort((a, b) => a.order - b.order)
    .map((question, index) => ({
      ...question,
      key: (index + 1).toString(),
    }));

  const defaultFixedQuestions = [
    {
      key: "1",
      label: "Ask for Full Name",
      name: "fullName",
      placeholder: "Full Name",
      value: "true",
      question_type_internal_name: "fixed",
      value_type: 'text',
      required: true,
      active: true,
      order: 1
    },
    {
      key: "2",
      label: "Ask for Job Title",
      name: "jobTitle",
      placeholder: "Job Title",
      value: "true",
      question_type_internal_name: "fixed",
      value_type: 'text',
      required: true,
      active: true,
      order: 2
    },
    {
      key: "3",
      label: "Ask for Work Email",
      name: "workEmail",
      placeholder: "Work Email",
      value: "true",
      question_type_internal_name: "fixed",
      value_type: 'email',
      required: true,
      active: true,
      order: 3
    },
    {
      key: "4",
      label: "Ask for Phone",
      name: "phone",
      placeholder: "Phone",
      value: "true",
      question_type_internal_name: "fixed",
      value_type: 'text',
      required: true,
      active: true,
      order: 4
    },
    {
      key: "5",
      label: "Ask for Country",
      name: "country",
      placeholder: "Country",
      value: "false",
      question_type_internal_name: "fixed",
      value_type: 'text',
      required: false,
      active: false,
      order: 5
    },
  ];

  return fixedQuestions.length === 0 ? defaultFixedQuestions : formFixedQuestions;
};

export const generateCustomFixedQuestions = (customFixedQuestions = []) => {

  const formCustomFixedQuestions = customFixedQuestions.map((question, index) => ({
    ...question,
    key: (index + 1).toString(),
  }));

  const defaultCustomFixedQuestions = [
    {
      key: '1',
      label: "",
      name: "",
      placeholder: "Custom Question",
      value: "false",
      question_type_internal_name: "custom_fixed",
      value_type: 'url',
      required: false,
      active: true,
      order: 1
    },
  ];

  return customFixedQuestions.length === 0 ? defaultCustomFixedQuestions : formCustomFixedQuestions;
};

export const generateQualifyingQuestions = (qualifyingQuestions = []) => {
  const formQualifyingQuestions = qualifyingQuestions.map((question, index) => ({
    ...question,
    key: (index + 1).toString(),
    question_options: question.question_options.map((option, index) => ({
      ...option,
      key: (index + 1).toString(),
    })),
  }));

  const defaultQualifyingQuestions = [
    {
      key: "1",
      name: "",
      label: "",
      value: "true",
      value_type: "text",
      question_type_internal_name: "qualifying",
      active: true,
      required: true,
      order: 1,
      placeholder: "",
      question_options: [
        {
          key: "1",
          label: "",
          value: "",
          order: 1,
          active: true
        },
        {
          key: "2",
          label: "",
          value: "",
          order: 2,
          active: true
        },
        {
          key: "3",
          label: "",
          value: "",
          order: 3,
          active: true
        }
      ]
    }
  ];

  return qualifyingQuestions.length === 0 ? defaultQualifyingQuestions : formQualifyingQuestions;
};
