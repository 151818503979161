import { ReactComponent as Qualified } from 'assets/img/lead/qualified_arrow.svg';
import { ReactComponent as Disqualified } from 'assets/img/lead/disqualified_arrow.svg';
import { ReactComponent as Uncompleted } from 'assets/img/lead/uncompleted.svg';

function LeadStatus({ qualification }) {

    return (
        <>
            {qualification === "qualified" &&
                <div style={{ display: 'flex', alignItems: 'center', color: 'var(--gray-700)' }} className={'font-medium'}>
                    <Qualified style={{ marginRight: 5 }} />
                    Qualified
                </div>
            }
            {(qualification === "unqualified") &&
                <div style={{ display: 'flex', alignItems: 'center', color: 'var(--gray-700)' }} className={'font-medium'}>
                    <Disqualified style={{ marginRight: 5 }} />
                    Not Qualified
                </div>
            }
            {qualification === "uncompleted" &&
                <div style={{ display: 'flex', alignItems: 'center', color: 'var(--gray-700)' }} className={'font-medium'}>
                    <Uncompleted style={{ marginRight: 5 }} />
                    Uncompleted
                </div>
            }
        </>
    )
}

export default LeadStatus;