import React, { useEffect, useState } from "react";
import { AUTHENTICATED_URL } from "baseURL";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Switch, TimePicker, message } from "antd";
import { updateAvailability, deleteAvailability, createAvailability, deleteAllAvailabilities } from '../../services/availability';
import WSelect from "components/w-select/w-select";
// IMAGES
import { ReactComponent as GreenCheck } from "assets/img/availability/green-check.svg";
import { ReactComponent as Clock } from "assets/img/availability/clock.svg";
import { ReactComponent as ClockDisabled } from "assets/img/availability/clock_disabled.svg";
import { ReactComponent as Trash } from "assets/img/availability/trash-black.svg";
import { PlusOutlined, CalendarOutlined } from "@ant-design/icons";
import { useUser } from "../../context/UserContext";
import Loading from "components/Loading/Loading";
import timezonesData from 'utils/timezones.json';

// STYLES
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function Availability() {
  const { user } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  const [availabilities, setAvailabilities] = useState(null);
  const timezones = timezonesData;
  let token = localStorage.getItem("token");
  let navigate = useNavigate();

  useEffect(() => {
    setPageLoading(true);
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`${AUTHENTICATED_URL}/availabilities`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('response', response);
      setAvailabilities(response.data);
      setPageLoading(false);
    } catch (err) {
      console.error('Error fetching availabilities:', err);
      setPageLoading(false);
    }
  };

  const toggleDay = (weekday) => {
    const updatedAvailabilities = { ...availabilities };
    updatedAvailabilities[weekday].enabled = !updatedAvailabilities[weekday].enabled;

    if (!updatedAvailabilities[weekday].enabled) {
      updatedAvailabilities[weekday].ranges = [];
      deleteAllAvailabilities(weekday, token);
    }

    setAvailabilities(updatedAvailabilities);
  };

  const addTimeRange = (weekday) => {
    const newRange = { start: '09:00', end: '18:00' };
    const updatedAvailabilities = { ...availabilities };

    updatedAvailabilities[weekday].ranges.push(newRange);
    setAvailabilities(updatedAvailabilities);
    createAvailability(weekday, updatedAvailabilities[weekday].ranges, token);
    message.success('Availability added successfully');
  };

  const removeTimeRange = (weekday, rangeId) => {
    const updatedAvailabilities = { ...availabilities };
    updatedAvailabilities[weekday].ranges = updatedAvailabilities[weekday].ranges.filter(range => range.id !== rangeId);
  
    setAvailabilities(updatedAvailabilities);
    deleteAvailability(rangeId, token);
    message.success('Availability removed');
  };

  const handleRangeChange = (weekday, rangeId, field, value) => {
    const updatedAvailabilities = { ...availabilities };
    const rangeIndex = updatedAvailabilities[weekday].ranges.findIndex(range => range.id === rangeId);
    const availabilitiesToUpdate = updatedAvailabilities[weekday].ranges[rangeIndex]

    if (rangeIndex !== -1) {
      availabilitiesToUpdate[field] = value;
      setAvailabilities(updatedAvailabilities);
      updateAvailability(rangeId, availabilitiesToUpdate.start, availabilitiesToUpdate.end, token);
      message.success('Availability updated');
    }
  };

  const goToProfile = () => {
    navigate("/profile");
  }

  if (pageLoading || availabilities === null) {
    return (
      <div className="weyzo-loader-page-wrapper">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <div className="container-lg">
        <div style={{ marginBottom: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottomColor: "#ECECEC",
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              paddingBottom: 5,
            }}
          >
            <div className="title-purple" style={{ marginRight: 20 }}>
              Set your weekly hours
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <GreenCheck style={{ marginRight: 8 }} />
              <span
                className="font-medium"
                style={{ color: "var(--gray-700)", fontSize: 14 }}
              >
                Default Schedule{" "}
              </span>
            </div>
            {user && !user.calendar_id && (
              <div>
                <Button className="primary-btn font-medium" onClick={goToProfile}>
                  <CalendarOutlined />
                  Sync your Calendar
                </Button>
              </div>
            )}
          </div>
          <div
            className="font-medium"
            style={{ color: "var(--gray-placeholder-dark)", marginTop: 8 }}
          >
            Choose a time below to edit or create a new one that you can apply
            to your event types
          </div>
          <div style={{ marginTop: 50, width: "50%" }}>
            <WSelect
              options={timezones}
              label="Time Zone"
              placeholder={"(GMT) Western Europe Time, London, Lisbon, Casablanca"}
            />
          </div>
          <div style={{ marginTop: 30 }}>
            {daysOfWeek.map((day, index) => (
              <div
                key={index}
                className="container-card"
                style={{
                  marginBottom: 10,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  {availabilities[index]?.enabled ? (
                    <Clock style={{ marginRight: 20 }} />
                  ) : (
                    <ClockDisabled style={{ marginRight: 20 }} />
                  )}
                  <span
                    className="font-medium"
                    style={{
                      color: availabilities[index]?.enabled 
                        ? "var(--primary)"
                        : "var(--gray-placeholder-light)",
                    }}
                  >
                    {day}
                  </span>
                </div>
                {availabilities[index]?.enabled  && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 3,
                    }}
                  >
                    { availabilities[index].ranges.map((range, rangeIndex) => (
                        <div
                          key={rangeIndex}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <TimePicker
                            value={moment(range.start, "HH:mm")}
                            onChange={(time) => handleRangeChange(index, range.id, 'start', time.format("HH:mm"))}
                            format="HH:mm"
                            bordered={false}
                            suffixIcon={null}
                          />
                          <TimePicker
                            value={moment(range.end, "HH:mm")}
                            onChange={(time) => handleRangeChange(index, range.id, 'end', time.format("HH:mm"))}
                            format="HH:mm"
                            bordered={false}
                            suffixIcon={null}
                          />
                          <Button
                            type="link"
                            onClick={() => removeTimeRange(index, range.id)}
                            icon={<Trash />}
                          />
                        </div>
                      ))}
                  </div>
                )}
                {!availabilities[index]?.enabled && (
                  <div
                    className="font-medium"
                    style={{
                      alignSelf: "center",
                      color: "var(--gray-700)",
                      flex: 3,
                      textAlign: "center",
                    }}
                  >
                    Not available
                  </div>
                )}
                <div
                  style={{
                    alignSelf: "center",
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  {availabilities[index]?.enabled && (
                    <Button
                      className="primary-btn-outline font-medium"
                      size="small"
                      onClick={() => addTimeRange(index)}
                      style={{ marginRight: 30 }}
                    >
                      <PlusOutlined />
                      <span>Interval</span>
                    </Button>
                  )}

                  <Switch
                    checked={availabilities[index]?.enabled || false}
                    onChange={() => toggleDay(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Availability;
