import { API_URL, BASE_URL } from "../baseURL";
import axios from 'axios';

let loginInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// OBTAIN CSRF TOKEN FOR CALLS
export const checkLogin = async () => {
  const token = localStorage.getItem("token");
  
  if (!token) {
    return Promise.reject("No token provided");
  }

  try {    
    const result = await axios.get(`${API_URL}/current_user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (result.status >= 200 && result.status < 300) {
      console.log('User exists and is logged in');
      return result.data;
    } else {
      return Promise.reject("Failed to fetch user data");
    }
  } catch (error) {
    console.error('Error while checking login:', error);
    return Promise.reject(error);
  }
};

export const register = (email, password) => {
  const registerData = {
    email: email,
    password: password
  };

  return new Promise((resolve, reject) => {
    loginInstance
      .post("/users", { user: registerData })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.error("ERR REGISTER", err);
        reject(err);
      });
  });
};
