import React, { useState, useEffect, useRef, useCallback } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { message, Avatar, Button, Modal } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { BASE_URL, API_URL, AUTHENTICATED_URL } from "../../baseURL";

import { ReactComponent as Calendar } from "assets/img/profile/calendar_connect.svg";
import { ReactComponent as Check } from "assets/img/profile/check.svg";

import { updateNotification } from "services/notification-report";

import NotificationSettings from "./components/NotificationSettings";
import ReportSettings from "./components/ReportSettings";
import WInput from "components/w-input/w-input";
import WSelect from "components/w-select/w-select";
import Loading from "components/Loading/Loading";
import timezonesData from 'utils/timezones.json';
import styles from './profile.module.css';

function Profile() {
  const token = localStorage.getItem("token");
  const { user, setUser } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  const inputHidden = useRef(null);
  const location = useLocation();
  const [syncStatus, setSyncStatus] = useState(false);

  useEffect(() => {
    if (user && !user.editUser) {
      setUser((prevUser) => ({
        ...prevUser,
        editUser: true,
      }));
      setPageLoading(false);
    } else {
      setPageLoading(false);
    }
  }, [location]);

  const googleAuthentication = useGoogleLogin({
    onSuccess: async (tokenResponse) => handleCalendarSyncSuccess(tokenResponse),
    onError: () => {
      setSyncStatus(false);
      message.error('Error syncing Google Calendar.');
    },
    scope: 'https://www.googleapis.com/auth/calendar',
    flow: 'implicit',
    access_type: 'offline',
    prompt: 'consent'
  });

  const handleCalendarSyncSuccess = async (tokenResponse) => {
    const { access_token, refresh_token, expires_in } = tokenResponse;

    axios.post(`${API_URL}/sync_google_calendar`,
      {
        google_calendar: {
          token: access_token,
          refresh_token: refresh_token,
          expires_in: expires_in
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then((response) => {
      setSyncStatus(true);
      setUser((prevUser) => ({
        ...prevUser,
        google_token: response.data.google_token,
      }));
      message.success(response.data.message);
    })
    .catch((error) => {
      setSyncStatus(false);
      message.error(error);
      console.error(error);
    });
  };

  const handleCalendarUnsyncSuccess = async () => {
    try {
      const response = await axios.post(`${API_URL}/unsync_google_calendar`, {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
  
      message.success(response.data.message);
      setSyncStatus(false);
      setUser((prevUser) => ({
        ...prevUser,
        google_token: null,
      }));
    } catch (error) {
      console.error('Error disconnecting Google Calendar:', error);
      message.error('Error disconnecting Google Calendar');
    }
  };

  const resetPassword = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/users/password`, {
        user: { email: user.email },
      });
  
      message.success(
        "Reset password instructions have been sent to the user's email address."
      );
    } catch (error) {
      message.error("Error sending password instructions");
      console.error(error);
    }
  };

  const updateUserImage = useCallback(async (imageData) => {
    if (!imageData) return;
    if (imageData.size > 1048576) {
      message.error("Image size is too large. Max size is 1MB");
      return;
    }
    const formData = new FormData();
    if (imageData) {
      formData.append('user[image]', imageData);
    } else {
      formData.append('user[image]', '');
    }

    try {
      const response = await axios.patch(`${AUTHENTICATED_URL}/profile`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setUser((prevUser) => ({
        ...prevUser,
        editUser: true,
        image_url: response.data.current_user.image_url,
      }));
      message.success(response.data.message);
      return response.data;
    } catch (error) {
      message.error("Error updating user");
      console.error(error);
      throw error;
    }
  }, [user, setUser, token]);


  const getImageUser = () => {
    inputHidden.current.click();
  };

  const updateUser = async () => {
    let params = {
      user: {
        full_name: user.full_name
      }
    };
  
    try {
      const response = await axios.patch(`${AUTHENTICATED_URL}/profile`, params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      setUser((prevUser) => ({
        ...prevUser,
        ...response.data.current_user,
        editUser: true,
      }));
  
      message.success("User updated successfully");
      return response.data;
    } catch (error) {
      message.error("Error updating user");
      console.error(error);
      throw error;
    }
  };

  const updateNotificationSetting = async (event, name) => {
    await updateNotification({ name, value: event.target.checked })
      .then((response) => {
        setUser(() => ({
          ...user,
          notification_setting: response.data.notification_settings,
        }));
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error("Error updating user notifications");
        console.error(error);
      }
    );
  }

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  return (
    user.id !== null && (
      <>
        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div className={`title-purple ${styles.profileRow}`}>
            Personal Information
            {user.editUser ? (
              <Button
                size="large"
                className="primary-btn font-regular"
                onClick={() => setUser({ ...user, editUser: false })}
              >
                Edit
              </Button>
            ) : (
              <Button
                size="large"
                className="primary-btn font-regular"
                onClick={() => updateUser()}
              >
                Save
              </Button>
            )}
          </div>
          <div className={styles.flexRow}>
            <div className={styles.flexColumn}>
              <div className={styles.centeredFlex}>
                <Avatar
                  style={{ marginRight: 20 }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={`${process.env.REACT_APP_HOST_NAME}/${user.image_url}`}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="title-purple">{user.full_name}</span>
                  <span className="default-text">{user.email}</span>
                </div>
              </div>
              <div>
                <Button
                  size="large"
                  className="primary-btn-outline font-regular"
                  style={{ marginRight: 30 }}
                  onClick={() => getImageUser()}
                >
                  Upload Image
                </Button>
                <input
                  style={{ display: "none" }}
                  ref={inputHidden}
                  type="file"
                  accept="image/jpeg, image/png, image/jpg, image/gif"
                  onChange={(e) => updateUserImage(e.target.files[0])}
                />
                <Button
                  size="large"
                  onClick={() => updateUserImage(false)}
                  className="primary-btn font-regular"
                >
                  Delete Image
                </Button>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <WInput
                label="Name"
                value={user.full_name}
                placeholder="John"
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setUser({ ...user, full_name: value })}
                disabled={user.editUser}
              />
              <WInput
                label="Work Email"
                value={user.email}
                placeholder="john.doe@weyzo.io"
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setUser({ ...user, email: value })}
                disabled
              />
              <WSelect
                label="Time Zone"
                options={timezonesData}
                value={user.userTimeZone}
                placeholder="(GMT) Western Europe Time, London, Lisbon, Casablanca"
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setUser({ ...user, userTimeZone: value })}
                disabled={user.editUser}
              />
            </div>
          </div>
        </div>
        {/* CHANGE USER PASSWORD */}
        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div className={`title-purple ${styles.profileRow}`}>
            Update password
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginRight: 30, marginBottom: 0 }}>
              Forgot your password?
            </p>

            <Button className="primary-btn font-regular" size="large" onClick={resetPassword}>
              Reset Password
            </Button>
          </div>
        </div>

        <NotificationSettings onChangeSwitch={updateNotificationSetting} notificationSetting={user.notification_setting} />

        <ReportSettings onChangeSwitch={updateNotificationSetting} notificationSetting={user.notification_setting} />

        {/* CONNECT TO YOUR CALENDAR */}
        <div className="container-lg">
          <div
            className="title-purple"
            style={{
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
            }}
          >
            Calendar Connection
          </div>
          <div
            className="font-medium"
            style={{ color: "var(--gray-placeholder-dark)", marginBottom: 30 }}
          >
            Connect your calendar to let weyzo know when you're available and
            update your calendar as events are scheduled.
          </div>
          <div
            className="container-card"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Calendar style={{ marginRight: 30 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <p
                  className="font-medium"
                  style={{ color: "var(--dark-900)", margin: 0, fontSize: 16 }}
                >
                  {user.email}
                </p>
                <p
                  className="font-regular"
                  style={{ color: "var(--dark-700)", margin: 0, fontSize: 14 }}
                >
                  Google Calendar
                </p>
              </div>
            </div>

            <div
              className="font-medium"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--gray-700)",
              }}
            >
              {user.google_token || syncStatus ? (
                <>
                  <Check style={{ marginRight: 10 }} /> Connected
                </>
              ) : (
                <>
                  Disconnected
                </>
              )}
            </div>

            <div>
              {user.google_token || syncStatus ? (
                <Button
                  className="danger-btn-rounded font-bold"
                  style={ { marginLeft: 16 } }
                  onClick={() => {
                    Modal.confirm({
                      title: 'Are you sure you want to disconnect Google Calendar?',
                      onOk() {
                        handleCalendarUnsyncSuccess();
                      },
                    });
                  }}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  className="primary-btn-rounded font-bold"
                  onClick={() => googleAuthentication()}
                >
                  Connect
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Profile;
