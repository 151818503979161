import React from "react";

import { PlusCircleOutlined } from "@ant-design/icons";

import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import "./components.module.css";
import styles from "./components.module.css";

import QualifyingQuestion from "./QualifyingQuestion";

const QualifyingQuestionsPanel = ({ qualifyingQuestions, setQualifyingQuestions, onDeleteQualyQuestion }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setQualifyingQuestions((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over.id);
        const updatedQualifyingQuestions = arrayMove(prev, activeIndex, overIndex);
  
        const qualifyingQuestionsWithOrder = updatedQualifyingQuestions.map((question, index) => ({
          ...question,
          order: index + 1,
        }));
  
        return qualifyingQuestionsWithOrder;
      });
    }
  };

  const addQualifyingQuestion = () => {
    const lastQualiQ = qualifyingQuestions[qualifyingQuestions.length - 1];
    const newIndex = lastQualiQ ? Number(lastQualiQ.key) + 1 : 1;
    const newQualifyingQuestion = {
      key: newIndex.toString(),
      label: "",
      name: "",
      value: "true",
      value_type: "text",
      question_type_internal_name: "qualifying",
      active: true,
      required: true,
      order: newIndex + 1,
      placeholder: "",
      question_options: [
        {
          key: "1",
          label: "",
          value: "",
          order: 1,
          active: true
        },
        {
          key: "2",
          label: "",
          value: "",
          order: 2,
          active: true
        },
        {
          key: "3",
          label: "",
          value: "",
          order: 3,
          active: true
        }
      ]
    };
    setQualifyingQuestions((prev) => [...prev, newQualifyingQuestion]);
  };

  return (
    <>
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCenter}
      >
        <SortableContext
          items={qualifyingQuestions.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          {qualifyingQuestions
            .filter(question => question.active)
            .map((question, questionIndex) => {
            return (
              <>
                <QualifyingQuestion
                  questionIndex={questionIndex}
                  question={question}
                  key={question.key}
                  setQualifyingQuestions={setQualifyingQuestions}
                  onDeleteQualyQuestion={onDeleteQualyQuestion}
                />
              </>
            );
          })}
        </SortableContext>
      </DndContext>
      <div
        className={styles["container-add-question"]}
        onClick={addQualifyingQuestion}
      >
        <PlusCircleOutlined />
        <p>Add Question</p>
      </div>
    </>
  );
};

export default QualifyingQuestionsPanel;
