import axios from 'axios';

import { AUTHENTICATED_URL } from "baseURL";

export const createAvailability = async (weekday, ranges, token) => {
  const params = {
    weekday: weekday,
    start_time: ranges[0].start,
    end_time: ranges[0].end
  };

  try {
    await axios.post(`${AUTHENTICATED_URL}/availabilities`, { availability: params }, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Error creating availability:', error);
  }
}

export const updateAvailability = async (id, start_time, end_time, token) => {
  const params = {
    start_time: start_time,
    end_time: end_time
  };

  try {
    await axios.patch(`${AUTHENTICATED_URL}/availabilities/${id}`, { availability: params }, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }, 
    });
  } catch (error) {
    console.error('Error updating availability:', error);
  }
};

export const deleteAvailability = async (id, token) => {
  try {
    await axios.delete(`${AUTHENTICATED_URL}/availabilities/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Error deleting availability:', error);
  }
};

export const deleteAllAvailabilities = async (weekday, token) => {
  try {
    await axios.post(`${AUTHENTICATED_URL}/availabilities/destroy_all_for_day`, { weekday }, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Error deleting all availabilities:', error);
  }
}
