import designIcon from '../assets/img/formBuilder/design_icon.svg'
import fixedIcon from '../assets/img/formBuilder/fixed_questions_icon.svg'
import legalIcon from '../assets/img/formBuilder/privacy_policy_icon.svg'
import qualifyingIcon from '../assets/img/formBuilder/qualifying_questions_icon.svg'
import meetingIcon from '../assets/img/formBuilder/camera_icon.svg'
import embedIcon from '../assets/img/formBuilder/embed_icon.svg'
import ctaIcon from '../assets/img/formBuilder/CTA_Icon.svg'

export const useIcons = icon => {
    switch(icon) {
        case 'designIcon':
            return designIcon
        case 'fixedIcon':
            return fixedIcon
        case 'legalIcon':
            return legalIcon
        case 'qualifyingIcon':
            return qualifyingIcon
        case 'meetingIcon':
            return meetingIcon
        case 'embedIcon':
            return embedIcon
        case 'ctaIcon':
            return ctaIcon
        default:
            break
    }

}