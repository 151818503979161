import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Input } from "antd";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import {
  addQuestionOption,
  removeQuestionOption,
  updateQuestionValue,
} from "../../../services/questionOptionService";

import "./components.module.css";
import styles from "./components.module.css";
import "../formBuilder.module.css";
import stylesForm from "../formBuilder.module.css";

import QuestionOption from "./QuestionOption";

const QualifyingQuestion = (props) => {
  const { questionIndex, question, setQualifyingQuestions, onDeleteQualyQuestion } = props;
  const [qualifyingQuestionOptions, setQualifyingQuestionOptions] = useState([]);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setQualifyingQuestionOptions(question.question_options);
  }, [question.question_options]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: question.key });
  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { control } = useFormContext();

  const handleQuestionOptionsDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setQualifyingQuestionOptions((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over.id);
        const updatedQualifyingQuestionOptions = arrayMove(prev, activeIndex, overIndex);

        const qualifyingQuestionOptionsWithOrder = updatedQualifyingQuestionOptions.map((question, index) => ({
          ...question,
          order: index + 1,
        }));
  
        return qualifyingQuestionOptionsWithOrder;
      });
    }
  };

  const handleAddOption = (questionKey) => {
    setQualifyingQuestions((prev) => addQuestionOption(prev, questionKey));
  };
  
  const handleRemoveOption = (questionKey, optionKey) => {
    setQualifyingQuestions((prev) => removeQuestionOption(prev, questionKey, optionKey));
  };
  
  const handleValueChange = (questionKey, optionKey, newValue) => {
    setQualifyingQuestions((prev) => updateQuestionValue(prev, questionKey, optionKey, newValue));
  };

  const deleteButtonStyle = {
    color: hovered ? '#F16063' : ''
  };

  return (
    <>
      <div
        key={question.name}
        ref={setNodeRef}
        style={sortableStyle}
        className={styles["qualy-questions"]}
      >
        <HolderOutlined
          className={`${styles["icon-holder"]} ${styles["icon-hor"]}`}
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
        />
        <div className={styles["container-qualy-input-card"]}>
          <div className={styles["container-qualy-title"]}>
            <Controller
              name={`qualifyingQuestions[${question.key}].label`}
              control={control}
              defaultValue={question.id ? question.label : ''}
              render={({ field }) => (
                <Input
                  placeholder={`Question ${questionIndex + 1}`}
                  size="large"
                  className={stylesForm.input}
                  {...field}
                />
              )}
            />
            <div className={styles["container-icons"]}>
              <DeleteOutlined
                onClick={(e) => {
                  onDeleteQualyQuestion(e);
                }}
                id={question.key}
                style={deleteButtonStyle}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              />
            </div>
          </div>
          <div className={styles["container-answers"]}>
            <DndContext
              onDragEnd={handleQuestionOptionsDragEnd}
              sensors={sensors}
              collisionDetection={closestCenter}
            > 
              {
                qualifyingQuestionOptions && qualifyingQuestionOptions.length > 0 && (
                  <SortableContext
                    items={qualifyingQuestionOptions.map((i) => i.key)}
                    strategy={verticalListSortingStrategy}
                  >
                    {
                      qualifyingQuestionOptions
                        .filter((option) => option.active)
                        .map((option, optionIndex) => {
                          return (
                            <>
                              <QuestionOption
                                key={optionIndex}
                                option={option}
                                name={`qualifyingQuestions[${question.key}].question_options`}
                                onDelete={() => handleRemoveOption(question.key, option.key)}
                                handleValueChange={(e) => handleValueChange(question.key, option.key, e.target.value)}
                              />
                            </>
                          );
                        })
                      }
                  </SortableContext>
                )
              }
            </DndContext>
          </div>
          <div
            className={styles["container-add-question"]}
            onClick={() => handleAddOption(question.key)}
          >
            <PlusCircleOutlined />
            <p>Add Answer</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualifyingQuestion;
