import React, { createContext, useContext, useState, useEffect } from 'react';
import { checkLogin } from '../services/auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [plan, setPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      checkLogin()
        .then((response) => {
          setUser(response.current_user)
          setPlan(response.current_user.plan.internal_name)
        })
        .catch((error) => {
          console.error('Authentication error:', error);
          setUser(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setUser(null);
      setPlan(null);
      setIsLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, plan, setPlan, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
