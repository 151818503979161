import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import "./switchInput.module.css";
import styles from "./switchInput.module.css";

const SwitchInput = ({ name, active, disabled }) => {
  const { control, unregister } = useFormContext();

  useEffect(() => {
    return () => {
      if (name.includes("qualy")) {
        unregister(name);
      }
    };
  }, [name, unregister]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={active}
      render={({ field }) => (
        <label className={styles.switch}>
          <input
            className={styles.input}
            type="checkbox"
            {...field}
            disabled={disabled}
            checked={field.value}
          />
          <span className={styles.slider}></span>
        </label>
      )}
    />
  );
};

export default SwitchInput;
