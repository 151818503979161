import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

import { useUser } from "../../context/UserContext";
import { createFormData } from "../../services/formService";

import Form from "./Form";

import {
  generateFixedQuestions,
  generateCustomFixedQuestions,
  generateQualifyingQuestions
} from "./utils/generateQuestions.js";

const FormBuilder = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [fixedQuestions, setFixedQuestions] = useState(generateFixedQuestions());
  const [customFixedQuestions, setCustomFixedQuestions] = useState(
    generateCustomFixedQuestions()
  );
  const [qualifyingQuestions, setQualifyingQuestions] = useState(generateQualifyingQuestions());
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();

  const formData = {
    domain: "",
    title: "",
    subtitle: "",
    logo: "",
    meeting_title: "",
    meeting_description: "",
    meeting_duration: 30,
    video_call: user.calendar_id ? false : true,
    logo_url: "",
    min_score: 0,
    primary_color: "#8847E6",
    secondary_color: "#FCF9F0",
    button_text: "Request a Demo",
    button_background: "#8847E6",
    button_text_color: "#ffffff",
    status: "",
    watermark_disabled: false,
    completed_forms: 0,
    fixed_questions: [],
    embed_type: "",
    custom_fixed_questions: [],
    qualifying_questions: [],
  };

  const onPublish = async (data) => {
    setLoading(true);

    const formData = await createFormData({
      formData: data,
      logo: logo,
      fixedQuestions: data.fixedQuestions,
      qualifyingQuestions: data.qualifyingQuestions,
      customFixedQuestions: customFixedQuestions,
      dataCustomFixedQuestions: data.customFixedQuestions,
      status: 'active',
    });

    if (formData.form.id) {
      setLoading(false);
      message.success("Form created successfully");
      navigate("/forms", { state: { from: "NewForm" } });
    } else {
      message.error("Oops, something went wrong");
    }
  };

  const onDraft = async (data) => {
    setLoading(true);

    if (!data.title) {
      message.error("Please enter a form title");
      setLoading(false);
      return;
    }

    const formData = await createFormData({
      formData: data,
      logo: logo,
      fixedQuestions: data.fixedQuestions,
      qualifyingQuestions: data.qualifyingQuestions,
      customFixedQuestions: customFixedQuestions,
      dataCustomFixedQuestions: data.customFixedQuestions,
      status: 'draft',
    });

    if (formData.form.id) {
      setLoading(false);
      message.success("Form created successfully");
      navigate('/forms', { state: { from: "NewForm" } });
    } else {
      message.error("Oops, something went wrong");
    }
  };

  return (
    <>
      <Form
        formData={formData}
        loading={loading}
        logo={logo}
        setLogo={setLogo}
        fixedQuestions={fixedQuestions}
        setFixedQuestions={setFixedQuestions}
        customFixedQuestions={customFixedQuestions}
        setCustomFixedQuestions={setCustomFixedQuestions}
        qualifyingQuestions={qualifyingQuestions}
        setQualifyingQuestions={setQualifyingQuestions}
        onPublish={onPublish}
        onDraft={onDraft}
      />
    </>
  );
};

export default FormBuilder;
