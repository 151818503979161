import React, { useEffect } from "react";
import { BASE_URL } from "../../baseURL";
import axios from "axios";
import { Avatar, Button, Layout, Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useUser } from "context/UserContext";

// STYLES
import styles from "./header.module.css";

// STORE
import useGlobalStore from "store/globalStore";
import { ReactComponent as Profile } from "assets/img/header/profile.svg";
import { ReactComponent as Company } from "assets/img/header/company.svg";
import { ReactComponent as Logout } from "assets/img/header/logout.svg";
import { ReactComponent as CreditCard } from "assets/img/credit-card.svg";

import { UpgradeButton } from "components/Buttons/Buttons";
import { message } from "antd";

function Header() {
  const activeRoute = useGlobalStore((state) => state.activeRoute);
  const setActiveRoute = useGlobalStore((state) => state.setActiveRoute);
  const { user, setUser, plan } = useUser();
  const billingPortalUrl = 'https://billing.stripe.com/p/login/5kAeVh3f45243VCdQQ'
  let navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("Header rendered with user:", user.full_name);
  }, [user]);

  const signOut = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/users/sign_out`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
  
      if (response.status === 200) {
        message.success(response.data.message);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        setUser(null);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const menu = (
    <Menu
      className={`${styles["container-dropdown"]}`}
      items={[
        {
          key: "1",
          label: (
            <div style={{ width: 200, borderRadius: 50 }}>
              <span className={styles.menuUsername}>
                { user?.full_name }
              </span>
              <br />
              <span style={{ fontSize: 13 }}>
                { user?.email }
              </span>
            </div>
          ),
        },
        {
          type: "divider",
        },
        {
          key: "2",
          label: (
            <button
              className={styles.menuItem}
              onClick={() => {
                setActiveRoute({ name: "Profile" });
                navigate("/profile");
              }}
            >
              <Profile className={styles.menuIcon} />
              My profile
            </button>
          ),
        },
        {
          key: "3",
          label: (
            <button
              className={styles.menuItem}
              onClick={() => {
                setActiveRoute({ name: "Company Profile" });
                navigate("/company_profile");
              }}
            >
              <Company className={styles.menuIcon} />
              Company Admin
            </button>
          ),
        },
        {
          key: "4",
          label: (
            plan !== 'free' && (
              <button
                className={styles.menuItem}
                onClick={() => {
                  setActiveRoute({ name: "Billing" });
                  window.location.href = billingPortalUrl;
                }}
              >
                <CreditCard className={styles.menuIcon} />
                Billing
              </button>
            )
          ),
        },
        {
          type: "divider",
        },
        {
          key: "5",
          label: (
            <button
              className={styles.menuItem}
              onClick={() => signOut()}
            >
              <Logout className={styles.menuIcon} />
              Logout
            </button>
          ),
        },
      ]}
    />
  );

  return (
    <Layout.Header className={styles.header}>
      <div className={`font-bold ${styles.headerTitle}`}>
        {activeRoute?.name ? activeRoute.name : "Analytics"}
      </div>
      <div>
        { plan === 'free' && <UpgradeButton />}
        <Dropdown
          style={{ borderRadius: 20 }}
          overlay={menu}
          placement="bottomRight"
        >
          <Button
            type="link"
            icon={
              user !== null ? (
                <Avatar size="large" src={`${process.env.REACT_APP_HOST_NAME}/${user.image_url}`} />
              ) : (
                <div
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 100,
                    backgroundColor: "#BBBBBB",
                  }}
                ></div>
              )
            }
          />
        </Dropdown>
      </div>
    </Layout.Header>
  );
}

export default Header;
