import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import Loading from '../components/Loading/Loading';

const PrivateRoute = ({ children }) => {
  const { user, isLoading } = useUser();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setIsUserLoaded(true);
    }
  }, [isLoading]);

  if (!isUserLoaded) {
    return (
      <div className="weyzo-loader-global-wrapper">
        <Loading />
      </div>
    ) 
  }

  if (!user || !user.id) {
    return <Navigate to="/login" />;
  }

  if (!user.profile_completed) {
    return <Navigate to="/welcome" />;
  }

  return children;
};

export default PrivateRoute;
