import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form, Input, Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import Loading from '../../components/Loading/Loading';
import { useUser } from "../../context/UserContext";

import Union from "../../assets/img/Union.svg";
import styles from "../login/login.module.css";
import { AUTHENTICATED_URL } from "../../baseURL";

function Welcome() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isBuilding, setIsBuilding] = useState(false);
  const [buildingMessage, setBuildingMessage] = useState("");

  useEffect(() => {
    setIsLoading(false);
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  const onFinish = async () => {
    setIsBuilding(true);
    setBuildingMessage("Creating your target audience from our AI engine...");
    const token = localStorage.getItem("token");
    axios.patch(`${AUTHENTICATED_URL}/profile/activate_account`, {
      user: {
        full_name: firstName + " " + lastName,
        company_website: company
      }
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then((response) => {
      setTimeout(() => {
        setIsBuilding(false);
        setUser(response.data.current_user);
        navigate("/");
      }, 3000);
    })
    .catch((error) => {
      setIsBuilding(false);
      message.error("An error occurred");
      console.error(error);
    })
  };

  if (isBuilding) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={Union}
          alt="Union"
        />
        <div className="building-message" style={{marginTop: 80, textAlign: 'center'}}>
          <div>
            <Loading />
            <p style={{marginTop: 16}}>{buildingMessage}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={Union}
          alt="Union"
        />

        { isLoading ? (
          <div style={{marginTop: 80}}>
            <Loading />;
          </div>
        ) : (
          <>
            <h1 className={"font-bold"} style={{ fontSize: 30 }}>
              Tell us about you
            </h1>
      
            <Form
              name="normal_login"
              style={{ width: 450 }}
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "49%" }}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First name!",
                      },
                    ]}
                  >
                    <Input
                      className={styles.Input}
                      darkback="true"
                      label="First name:"
                      placeholder="First name"
                      type="text"
                      value={firstName}
                      onChange={(value) => setFirstName(value.target.value)}
                    />
                  </Form.Item>
                </div>
      
                <div style={{ width: "49%" }}>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last name!",
                      },
                    ]}
                  >
                    <Input
                      className={styles.Input}
                      darkback="true"
                      label="Last name:"
                      placeholder="Last name"
                      type="text"
                      value={lastName}
                      onChange={(value) => setLastName(value.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
      
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  className={styles.Input}
                  darkback="true"
                  label="Work Email:"
                  placeholder="Company website"
                  type="text"
                  value={company}
                  onChange={(value) => setCompany(value.target.value)}
                />
              </Form.Item>
      
              <Button
                size="large"
                style={{ height: 46 }}
                className="primary-btn font-bold full-btn"
                htmlType="submit"
              >
                Continue
              </Button>
      
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 15,
                }}
              >
                <span
                  className="font-medium"
                  style={{ textAlign: "center", width: 200 }}
                >
                  By clicking "Continue" you agree to Weyzo's{" "}
                  <Button
                    type="text"
                    className="font-regular"
                    onClick={() => console.log("privacy policy")}
                  >
                    privacy policy
                  </Button>
                  .
                </span>
              </div>
            </Form>
          </>
        )}

      </div>
    </>
  );
}

export default Welcome;
