import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "axios";
import { BASE_URL } from "../../baseURL";
import { useUser } from "context/UserContext";

import { Form, Input, Button, message } from "antd";

import WeyzoLogo from "../../assets/img/WeyzoLogo.svg";
import Loading from '../../components/Loading/Loading';

import styles from "./login.module.css";

function Login() {
  let navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useUser();
  const [resetPassword, setResetPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });
  const [isLoading, setIsLoading] = useState(true);

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);

  useEffect(() => {
    if (user) {
      navigate("/");
    }

    if (queryParams.get("confirmed") === 'success') {
      message.success("Email confirmed successfully");
    }

    if (queryParams.get("confirmed") === 'failure') {
      message.success("Email confirmation failed, please try again or contact support");
    }

    setIsLoading(false);
  }, [user, navigate]);

  const handleChange = (event) => {
    setLoginData({...loginData, [event.target.name]: event.target.value});
  };

  const goResetPassword = async () => {
    if (!resetPassword) setResetPassword(true);
    else setResetPassword(false);
  };

  const goRegister = () => {
    navigate("/register");
  };

  const signIn = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/users/sign_in`, { user: loginData });
      if (response.headers.authorization) {
        const token = response.headers.authorization.replace("Bearer ", "");
        localStorage.setItem("token", token);
        localStorage.setItem("userId", response.data.current_user.id);
        setUser(response.data.current_user)
        message.success("Logged in successfully");
        setIsLoading(false);
        navigate("/");
      }
    }
    catch(err) {
      setIsLoading(false);
      console.error(err);
      message.error("Login error");
    }
  };

  const sendPasswordEmail = async () => {
    return await fetch(
      `${BASE_URL}/api/v1/entrance/send-password-recovery-email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: loginData.email,
        }),
      }
    )
    .then((_res) => {
      message.success(
        "Reset password instructions have been sent to your email account."
      );
      navigate("/checkEmail", { state: { email: loginData.email } });
      setResetPassword(false);
    })
    .catch((error) => {
      message.error("Error sending password instructions");
      console.error(error);
    });
  };

  if (isLoading) {
    return (
      <div className="weyzo-loader-page-wrapper">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={WeyzoLogo}
          alt="WeyzoLogo"
        />
        <div style={{ fontWeight: "bold" }} className="medium-text">
          { resetPassword ? "Enter your email" : "Welcome" }
        </div>
      </div>
      <div className={styles.loginContainer}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={signIn}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Work Email!",
              },
            ]}
          >
            <Input
              name="email"
              className={styles.Input}
              darkback="true"
              label="Work Email:"
              placeholder="Type your work email"
              type="text"
              value={loginData.email}
              onChange={handleChange}
            />
          </Form.Item>

          {!resetPassword && (
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                name="password"
                className={styles.Input}
                darkback="true"
                label="Password:"
                placeholder="Password"
                type="text"
                style={{ marginBottom: 15 }}
                value={loginData.email}
                onChange={handleChange}
              />
            </Form.Item>
          )}

          {!resetPassword && (
            <div style={{ marginBottom: 30 }}>
              <span>
                <Button
                  type="link"
                  onClick={goResetPassword}
                  style={{ color: "#6a42e9" }}
                  className={`font-regular ${styles["link-button"]}`}
                >
                  Forgot your password?
                </Button>
              </span>
            </div>
          )}

          {resetPassword && (
            <div style={{ marginBottom: 30 }}>
              <span
                className="font-medium"
                style={{ marginRight: 5, color: "var(--gray-700)" }}
              >
                Already have an account?
              </span>
              <span>
                <Button
                  type="text"
                  onClick={goResetPassword}
                  style={{ color: "#6a42e9" }}
                  className="font-regular"
                >
                  Go back to login
                </Button>
              </span>
            </div>
          )}

          <Form.Item>
            {!resetPassword && (
              <Button
                size="large"
                style={{ height: 46 }}
                className="primary-btn font-regular full-btn"
                htmlType="submit"
              >
                Sign In
              </Button>
            )}
            {resetPassword && (
              <Button
                size="large"
                style={{ height: 46 }}
                className="primary-btn font-regular full-btn"
                onClick={sendPasswordEmail}
              >
                Send password recovery instructions
              </Button>
            )}
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <span className="font-medium">
              Don't have an account?
              <Button
                type="text"
                style={{ color: "#6a42e9" }}
                className="font-regular"
                onClick={goRegister}
              >
                Sign up
              </Button>
            </span>
          </div>
        </Form> 
      </div>
    </>
  );
}

export default Login;
