import React, { useState, useEffect } from "react";
import axios from 'axios';
import WInput from "components/w-input/w-input";
import WSelect from "components/w-select/w-select";
import LeadStatus from "components/lead-status/lead-status";
// IMAGES
import { ReactComponent as Edit } from "assets/img/edit.svg";
import { ReactComponent as Trash } from "assets/img/trash.svg";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { message, Popconfirm, Button } from "antd";

import { useParams } from "react-router-dom";
import { AUTHENTICATED_URL } from "../../baseURL";

import styles from "./lead.module.css";

function Lead() {
  let token = localStorage.getItem("token");
  let params = useParams();
  let id = params.id;
  const [lead, setLead] = useState(null);
  const [editLead, setEditLead] = useState(false);
  const [usersForSelect, setUsersForSelect] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getLead();
  }, []);

  const getLead = async () => {
    try {
      const response = await axios.get(`${AUTHENTICATED_URL}/leads/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const leadData = response.data.lead;
      const usersData = response.data.users.data;
      setLead(leadData);
      const formattedUsers = usersData.map((user) => ({
        value: user.id,
        name: user.full_name,
      }));
      setUsersForSelect(formattedUsers);
      return leadData;
    } catch (error) {
      message.error("Lead not found");
      console.error(error);
    }
  };

  const updateLead = async () => {
    try {
      const response = await axios.patch(
        `${AUTHENTICATED_URL}/leads/${id}`,
        lead,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const leadData = response.data.lead;
  
      setLead(leadData);
      setEditLead(false);
      message.success("Lead updated successfully");
    } catch (error) {
      message.error("Lead update failed");
      console.error(error);
    }
  };

  const confirm = (e) => {
    console.log(e);
    deleteLead(lead.id);
  };

  const cancel = (e) => {
    console.log(e);
  };

  async function deleteLead(lead_id) {
    try {
      const response = await axios.delete(`${AUTHENTICATED_URL}/leads/${lead_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      message.success("Lead has been deleted");
      navigate("/leads");
    } catch (error) {
      console.error(error);
      message.error("Error deleting lead");
    }
  }

  return (
    lead != null && (
      <>
        <div className="container-lg">
          <div style={{ marginBottom: 30 }}>
            <div className={styles.flexContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="title-purple" style={{ marginRight: 20 }}>
                  {lead.name}
                </div>
                <div style={{ marginRight: 20 }}>
                  <LeadStatus qualification={lead.status} />
                </div>
                <div className="font-regular">
                  <span className="font-bold">{lead.lead_scoring}</span>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {editLead ? (
                  <>
                    <Button
                      style={{ marginRight: 20 }}
                      size="large"
                      className="primary-btn font-regular"
                      onClick={() => updateLead()}
                    >
                      Save
                    </Button>
                    <Button
                      style={{ marginRight: 20 }}
                      size="large"
                      className="tertiary-btn font-regular"
                      onClick={() => setEditLead(false)}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Edit className={styles.editButton} onClick={() => setEditLead(true)} />
                )}
                <Popconfirm
                  title="Are you sure to delete this lead?"
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Trash className={styles.trashDeleteButton} />
                </Popconfirm>
              </div>
            </div>
            <div
              className="font-medium"
              style={{ color: "var(--gray-placeholder-dark)", marginTop: 8 }}
            >
              Created at: {Moment(lead.createdAt).format("DD-MM-YYYY - H:m")} ·
              Updated at: {Moment(lead.updatedAt).format("DD-MM-YYYY - H:m")}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", columnGap: 30 }}>
            <div style={{ flexGrow: 1 }}>
              <WInput
                label="Work Email"
                placeholder="john@doee.com"
                value={lead.email}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setLead({ ...lead, email: value })}
                disabled={!editLead}
              />
              <WInput
                label="Position"
                placeholder="Product Manager"
                value={lead.position}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setLead({ ...lead, position: value })}
                disabled={!editLead}
              />
              <WSelect
                style={{ marginBottom: 20 }}
                value={lead.user.id}
                placeHolder={"Assigned To"}
                label="Assigned To:"
                options={usersForSelect}
                onChange={(value) => setLead({ ...lead, user_id: value })}
                disabled={!editLead}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <WInput
                label="Phone"
                placeholder="+33654458874"
                value={lead.phone}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setLead({ ...lead, phone: value })}
                disabled={!editLead}
              />
              <WInput
                label="Company"
                placeholder=""
                value={lead.company_name}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setLead({ ...lead, company_name: value })}
                disabled={!editLead}
              />
              <WInput
                label="Country"
                placeholder=""
                value={lead.country}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setLead({ ...lead, country: value })}
                disabled={!editLead}
              />
              <WInput
                label="Meeting"
                placeholder="01.01.22 - 20.30"
                value={Moment(lead.meeting_date).format("DD-MM-YYYY - H:m")}
                onChangeValue={(value) => setLead({ ...lead, meeting_date: value })}
                disabled={!editLead}
              />
            </div>
          </div>
        </div>

        <div className="container-lg" style={{ marginTop: 40 }}>
          <div style={{ borderBottom: "1px solid #ECECEC" }}>
            <div style={{ marginBottom: 5 }}>
              <span className="title-purple"> Qualifying Feedback </span>
            </div>
          </div>
          <div style={{ marginTop: 16 }}>
            <div className="container-card" style={{ width: "100%" }}>
              <div
                className="font-medium"
                style={{ color: "var(--gray-900)" }}
              >
                {lead.lead_scoring_feedback}
              </div>
            </div>
          </div>
        </div>

        <div className="container-lg" style={{ marginTop: 40 }}>
          <div style={{ borderBottom: "1px solid #ECECEC" }}>
            <div style={{ marginBottom: 5 }}>
              <span className="title-purple"> Form Responses </span> <span> { lead.form.title } </span> 
            </div>
          </div>
          <div className={styles.flexRowWrap}>
            {
              lead.lead_answers.length === 0 ? (
                <div className="container-card" style={{ width: "30%" }}>
                  <div
                    className="font-medium"
                    style={{ color: "var(--gray-900)" }}
                  >
                    No answers
                  </div>
                </div>
              ) : (
                lead.lead_answers.map((answer, i) => {
                  return (
                    <div className="container-card" style={{ width: "30%" }}>
                      <div
                        className="font-medium"
                        style={{ color: "var(--gray-900)" }}
                      >
                        {answer.question_name}
                      </div>
                      <div
                        className="font-medium"
                        style={{ color: "var(--gray-700)" }}
                      >
                        {answer.answer_value}
                      </div>
                    </div>
                  );
                })
              )
            }
          </div>
        </div>
      </>
    )
  );
}

export default Lead;
